<template>
	<div class="clean-campus">
		<div style="position: absolute;left: 0;top: 0;width: 100vw;height: 100%;display: flex;flex-direction: column;">
			<!-- 上面图片 -->
			<div><img style="width: 100%;margin: -108px;" src="/蓝绿粉色中国风廉洁文化微信公众号封面.jpg" @click="goToHome">
				<div style="flex: 1;background-image: url('/蓝绿粉色中国风廉洁文化微信公众号封面1.jpg');background-size: cover;">
					<MENU></MENU>
					<el-table :data="paginatedTableData" style="width: 100%; padding-left: 5%;" class="el-tables">
						<el-table-column class="el-table-column" width="1400">
							<template v-slot="scope">
								<a :href="generateLink(scope.row)" target="_blank">{{ scope.row.link }}</a>
							</template>
						</el-table-column>
						<el-table-column class="el-table-column" prop="date" width="150"></el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" :total="tableData.length" :page-size="pageSize"
						:current-page.sync="currentPage" @current-change="handlePageChange"></el-pagination>
				</div>
				<div class="footer">
					<div class="footer-content">
						<div class="footer-section">
							<h3>校区地址</h3>
							<p>关山校区：湖北省武汉市东湖高新区光谷一路206号</p>
							<p>鲁巷校区：湖北省武汉市洪山区珞瑜路1037号</p>
						</div>
						<div class="footer-section">
							<h3>联系方式</h3>
							<p>招生电话：027-87588821</p>
							<p>书记校长信箱 接访电话：027-87588771</p>
							<p>邮箱：hkzjyk@126.com</p>
						</div>
						<div class="footer-section">
							<h3>其他链接</h3>
							<p>
								<a href="https://www.hubstc.com.cn/" target="_blank" rel="noopener noreferrer">
									官网链接（点击跳转）
								</a>
							</p>
							<p>
								<a href="https://www.hbou.edu.cn/" target="_blank" rel="noopener noreferrer">
									开放大学
								</a>
							</p>
						</div>
						<div class="footer-section copyright">
							<p>&copy; 2024 湖北科技职业学院. 版权所有.</p>
							<p>鄂ICP备11015833号-2 </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>



<script>
import MENU from './MENU.vue';
export default {
	name: 'IntegrityEducationActivity',
	components: {
		MENU
	},
	computed: {
		paginatedTableData() {
			const start = (this.currentPage - 1) * this.pageSize;
			const end = this.currentPage * this.pageSize;
			return this.tableData.slice(start, end);
		},
	},
	methods: {
		handlePageChange(page) {
			this.currentPage = page;
		},
		generateLink(row) {
			return `${row.to}`;
		},
		goToHome(){
			this.$router.push('/')
		}
	},
	data() {
		return {
			tableData: [{
				date: "2024-06-20",
				link: "校团委举办“青廉画·清廉与青春同行”廉洁文化书画作品展活动",
				to: "https://www.hubstc.com.cn/info/1070/13245.htm"
			}, {
				date: "2024-06-17",
				link: "【国开时讯网】湖北开放大学举办开放教育“廉洁课”主题教学观摩活动",
				to: "https://www.hbou.edu.cn/info/1145/17609.htm"
			}, {
				date: "2024-06-12",
				link: "我校举行“青廉说·清廉与青春同行”主题演讲比赛活动",
				to: "https://www.hubstc.com.cn/info/1070/13215.htm"
			}, {
				date: "2023-11-08",
				link: "​湖北科技职业学院：“课堂+实践”阵地联动 在“青年”中播下“清廉”的种子",
				to: "https://www.hubstc.com.cn/info/1070/12469.htm"
			}, {
				date: "2023-09-27",
				link: "【学习强国】湖北科技职业学院：风清气正迎开学 廉洁教育不缺位",
				to: "https://www.hubstc.com.cn/info/1065/12277.htm"
			}, {
				date: "2023-08-15",
				link: "校纪委工会联合开展廉政家访活动",
				to: "https://www.hubstc.com.cn/info/1070/12060.htm"
			}, {
				date: "2023-04-24",
				link: "【“大思政课”建设系列报道二】金融版廉洁文化课开讲经济贸易学院助力学子系好“廉洁扣”",
				to: "https://www.hubstc.com.cn/info/1070/11516.htm"
			}, {
				date: "2022-11-04",
				link: "凝聚清风正气 弘扬廉洁文化 校团委开展“勤廉笃实 启航人生”主题演讲比赛",
				to: "https://www.hubstc.com.cn/info/1070/10848.htm"
			}, {
				date: "2022-10-26",
				link: "【中国教育在线】湖北科技职业学院2022年党风廉政建设宣教月活动纪实",
				to: "https://www.hubstc.com.cn/info/1065/10775.htm"
			}, {
				date: "2022-10-12",
				link: "工商管理学院开展“写青春故事 倡廉洁意识”微电影、漫画展示大赛s",
				to: "https://www.hubstc.com.cn/info/1070/10700.htm"
			}, {
				date: "2021-10-07",
				link: "【长江云】做‘廉心’月饼 庆‘廉味’双节",
				to: "https://www.hubstc.com.cn/info/1065/8487.htm"
			}, {
				date: "2021-06-18",
				link: "扣好人生第一粒扣子——工商管理学院召开毕业生“崇廉树人 谨守初心”廉政座谈会",
				to: "https://www.hubstc.com.cn/info/1070/7900.htm"
			}, {
				date: "2020-11-18",
				link: "敲响廉政警钟 筑牢思想防线 学校组织领导干部赴省党风廉政警示教育基地参观学习",
				to: "https://www.hubstc.com.cn/info/1068/6549.htm"
			}, {
				date: "2020-11-12",
				link: "【中华网】湖北广播电视大学第二十一个党风廉政建设宣教月活动纪实",
				to: "https://www.hubstc.com.cn/info/1065/6554.htm"
			}, {
				date: "2024-05-29",
				link: "学校举办开放教育“廉洁课”主题教学观摩活动",
				to: "https://www.hbou.edu.cn/info/1034/17577.htm"
			}, {
				date: "2023-12-18",
				link: "我校2023“崇廉尚礼，文明湖科”大学生礼仪文化知识竞赛圆满举办",
				to: "https://www.hbou.edu.cn/info/1034/17242.htm"
			}, {
				date: "2023-04-28",
				link: "【极目新闻】书香传廉韵，武汉建设者迎接世界读书日",
				to: "https://www.hbou.edu.cn/info/1145/16063.htm"
			}],
			currentPage: 1,
			pageSize: 10, // 每页显示5条记录 
		}
	}
};
</script>

<style scoped>
.clean-campus {
	padding: 20px;
}

.el-tables {
	font-size: 20px;
	font-weight: bolder;
}

.el-table-column {
	height: 600px;
}

a {
	color: inherit;
	/* 继承父元素的颜色 */
	text-decoration: none;
	/* 移除下划线 */
}

a:hover {
	color: inherit;
	/* 鼠标悬停时颜色不变 */
	text-decoration: none;
	/* 鼠标悬停时不显示下划线 */
}
.el-menu {
	background-color: #fff;
	padding-left: 300px;
	height: 80px;
	font-weight: bolder;
	padding-top: 30px;
}
.footer {
  background-color: rgb(206,223,226); 
  padding: 20px 0; /* 内边距 */
  text-align: center; /* 文字居中 */
  color: #333; /* 文字颜色 */
  font-size: 14px; /* 文字大小 */
}
 
.footer-content {
  display: flex; /* 使用 flex 布局 */
  flex-wrap: wrap; /* 允许换行 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中（在这个上下文中可能不太明显） */
  max-width: 1200px; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
}
 
.footer-section {
  flex: 1; /* 等宽布局 */
  min-width: 200px; /* 最小宽度 */
  padding: 10px; /* 内边距 */
  box-sizing: border-box; /* 包括内边距和边框在内计算宽度 */
}
 
.footer-section h3 {
  margin-top: 0; /* 去掉上边距 */
  font-size: 16px; /* 标题大小 */
  font-weight: bold; /* 加粗 */
}
 
.footer-section p {
  margin: 5px 0; /* 段落上下边距 */
}
 
.footer-section a {
  color: #007bff; /* 链接颜色 */
  text-decoration: none; /* 去掉下划线 */
}
 
.footer-section a:hover {
  text-decoration: underline; /* 悬停时显示下划线 */
}
 
.copyright {
  width: 100%; /* 全宽 */
  margin-top: 20px; /* 上边距 */
  border-top: 1px solid #ddd; /* 上边框 */
  padding-top: 10px; /* 上内边距 */
  font-size: 12px; /* 文字大小 */
}
</style>