<template>
    <div style="background-color: #cce0e5; display: flex; justify-content: center; align-items: center; height: 100px; width: 100%; flex-wrap: nowrap;">
        <span class="zhuye"><i class="el-icon-s-home" @click="goToHome"></i></span>
        <el-menu background-color="#cce0e5" class="el-menu-demo" mode="horizontal"
            text-color="#00424d" active-text-color="black">
            <el-menu-item index="1">
                <router-link style="text-decoration: none; font-size: 40px;" to="/clean-campus">清廉校园</router-link>
            </el-menu-item>
            <el-menu-item index="2">
                <router-link style="text-decoration: none; font-size: 40px;"
                    to="/party-style-month">党风廉政宣教月活动</router-link>
            </el-menu-item>
            <el-menu-item index="3">
                <router-link style="text-decoration: none; font-size: 40px;"
                    to="/integrity-education-activity">廉政教育活动</router-link>
            </el-menu-item>
            <el-menu-item index="4">
                <a style="text-decoration: none; font-size: 40px;"
                    href="https://www.hbjwjc.gov.cn/info/iList.jsp?cat_id=11821" target="_blank">纪律教育</a>
            </el-menu-item>
            <el-menu-item index="5">
                <a style="text-decoration: none; font-size: 40px;"
                    href="https://www.hbjwjc.gov.cn/info/iList.jsp?cat_id=10026" target="_blank">清廉视频</a>
            </el-menu-item>
            <el-menu-item index="6">
                <a style="text-decoration: none; font-size: 40px;" href="http://www.whdi.gov.cn/lianjiewenhua/"
                    target="_blank">廉洁文化</a>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {  
    name: 'Menu',
    methods:{
        goToHome(){
            this.$router.push('/')
        }
    }
    }
</script>

<style>
.el-menu-demo {
    display: flex; /* 确保使用 Flexbox 布局 */
    flex-wrap: nowrap; /* 防止菜单项换行 */
    white-space: nowrap; /* 确保菜单项内的文本不换行 */
    overflow-x: auto; /* 如果菜单项太多，允许水平滚动 */
    background-color: #cce0e5; /* 确保背景色覆盖整个菜单区域 */
    overflow-y: hidden;
}

.el-menu-item {
    flex: none; /* 防止菜单项自动调整大小 */
}

.el-menu-item a,
.el-menu-item router-link {
    line-height: 100%; /* 垂直居中文本，这里的高度应与父容器高度相匹配 */
    text-align: center; /* 水平居中文本 */
    width: auto; /* 根据内容自动调整宽度 */
    padding-top: 20px;
}

@media (max-width: 1600px) {
    .el-menu-demo {
        overflow-x: hidden;
    }
}

.zhuye {
    font-size: 50px;
}

</style>