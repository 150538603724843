<template>
	<div class="clean-campus">
		<div style="position: absolute;left: 0;top: 0;width: 100vw;height: 100%;display: flex;flex-direction: column;">
			<!-- 上面图片 -->
			<div><img style="width: 100%;margin: -108px;" src="/蓝绿粉色中国风廉洁文化微信公众号封面.jpg" @click="goToHome">
				<div style="flex: 1;background-image: url('/蓝绿粉色中国风廉洁文化微信公众号封面1.jpg');background-size: cover;">
					<MENU></MENU>
					<el-table :data="paginatedTableData" style="width: 100%; padding-left: 5%;" class="el-tables">
						<el-table-column class="el-table-column" width="1400">
							<template v-slot="scope">
								<a :href="generateLink(scope.row)" target="_blank">{{ scope.row.link }}</a>
							</template>
						</el-table-column>
						<el-table-column class="el-table-column" prop="date" width="150"></el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" :total="tableData.length" :page-size="pageSize"
						:current-page.sync="currentPage" @current-change="handlePageChange"></el-pagination>
				</div>
				<div class="footer">
					<div class="footer-content">
						<div class="footer-section">
							<h3>校区地址</h3>
							<p>关山校区：湖北省武汉市东湖高新区光谷一路206号</p>
							<p>鲁巷校区：湖北省武汉市洪山区珞瑜路1037号</p>
						</div>
						<div class="footer-section">
							<h3>联系方式</h3>
							<p>招生电话：027-87588821</p>
							<p>书记校长信箱 接访电话：027-87588771</p>
							<p>邮箱：hkzjyk@126.com</p>
						</div>
						<div class="footer-section">
							<h3>其他链接</h3>
							<p>
								<a href="https://www.hubstc.com.cn/" target="_blank" rel="noopener noreferrer">
									官网链接（点击跳转）
								</a>
							</p>
							<p>
								<a href="https://www.hbou.edu.cn/" target="_blank" rel="noopener noreferrer">
									开放大学
								</a>
							</p>
						</div>
						<div class="footer-section copyright">
							<p>&copy; 2024 湖北科技职业学院. 版权所有.</p>
							<p>鄂ICP备11015833号-2 </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>



<script>
import MENU from './MENU.vue';
export default {
	name: 'PartyStyleMonth',
	components: {
		MENU
	},
	computed: {
		paginatedTableData() {
			const start = (this.currentPage - 1) * this.pageSize;
			const end = this.currentPage * this.pageSize;
			return this.tableData.slice(start, end);
		},
	},
	methods: {
		handlePageChange(page) {
			this.currentPage = page;
		},
		generateLink(row) {
			return `${row.to}`;
		},
		goToHome(){
			this.$router.push('/')
		}
	},
	data() {
		return {
			tableData: [{
				date: "2023-09-15",
				link: "【2023年党风廉政建设宣传教育月】 风清气正迎开学 廉洁教育不缺位",
				to: "https://www.hubstc.com.cn/info/1070/12206.htm"
			}, {
				date: "2023-09-14",
				link: "【2023年党风廉政建设宣传教育月】清风工管 廉洁启航工商管理学院大力开展廉洁迎新",
				to: "https://www.hubstc.com.cn/info/1070/12192.htm"
			}, {
				date: "2023-09-11",
				link: "【2023年党风廉政建设宣传教育月】以书润心 与廉同行 经济贸易学院举办廉洁教育读书分享会",
				to: "https://www.hubstc.com.cn/info/1070/12142.htm"
			}, {
				date: "2023-09-07",
				link: "【2023年党风廉政建设宣传教育月】采购与招标管理中心与招标代理机构签订廉洁协议并开展集体廉政谈话",
				to: "https://www.hubstc.com.cn/info/1070/12124.htm"
			}, {
				date: "2023-07-13",
				link: "【2023年党风廉政建设宣传教育月】“五廉”润心 以文化人 我校开展党风廉政宣教月廉洁文化系列活动",
				to: "https://www.hubstc.com.cn/info/1068/11973.htm"
			}, {
				date: "2023-07-13",
				link: "【2023年党风廉政建设宣传教育月】厚植清廉理念 建设清廉体系 学校开展党风廉政宣教月系列活动",
				to: "https://www.hubstc.com.cn/info/1068/11972.htm"
			}, {
				date: "2023-06-26",
				link: "【2023年党风廉政建设宣传教育月】筑牢思想防线 严守党的纪律 学校召开党风廉政建设宣传教育月活动部署推进会暨警示教育大会",
				to: "https://www.hubstc.com.cn/info/1068/11921.htm"
			}, {
				date: "2022-10-12",
				link: "【2022年党风廉政建设宣教月】讲好“青廉课堂” 织牢年轻干部成长防护网",
				to: "https://www.hubstc.com.cn/info/1068/10694.htm"
			}, {
				date: "2022-10-05",
				link: "【2022年党风廉政建设宣教月】机电工程学院开展“勤廉笃实 启航人生”演讲比赛活动",
				to: "https://www.hubstc.com.cn/info/1070/10673.htm"
			}, {
				date: "2022-10-05",
				link: "【2022年党风廉政建设宣教月】倡树廉洁清风 共建和谐校园 ​ 软件工程学院开展廉政书法作品展活动",
				to: "https://www.hubstc.com.cn/info/1070/10663.htm"
			}, {
				date: "2022-10-04",
				link: "【2022年党风廉政建设宣教月】以“考”促“学” ——学校开展党纪法规知识测试活动",
				to: "https://www.hubstc.com.cn/info/1070/10660.htm"
			}, {
				date: "2022-10-04",
				link: "【2022年党风廉政建设宣教月】“廉风”拂面 “廉香”扑鼻 清廉文化绽放“清廉校园”",
				to: "https://www.hubstc.com.cn/info/1070/10657.htm"
			}, {
				date: "2022-09-30",
				link: "【2022年党风廉政建设宣教月】学工部（处）、武装部、团委党支部开展“纪法同行”主题党日活动",
				to: "https://www.hubstc.com.cn/info/1070/10654.htm"
			}, {
				date: "2022-09-30",
				link: "【2022年党风廉政建设宣教月】后勤服务集团党总支开展党风廉政教育系列活动",
				to: "https://www.hubstc.com.cn/info/1070/10650.htm"
			}, {
				date: "2022-09-28",
				link: "【2022年党风廉政建设宣教月】采购与招标管理中心对招标代理机构进行提醒谈话",
				to: "https://www.hubstc.com.cn/info/1070/10637.htm"
			}, {
				date: "2022-09-26",
				link: "【2022年党风廉政建设宣教月】以文化人 诵诗倡廉 ——宣传部党支部开展“清廉”诗词诵读活动",
				to: "https://www.hubstc.com.cn/info/1070/10617.htm"
			}, {
				date: "2022-09-26",
				link: "【2022年党风廉政建设宣教月】清风润工管廉洁育师德——工商管理学院开展党风廉政暨师德师风建设主题教育活动",
				to: "https://www.hubstc.com.cn/info/1070/10615.htm"
			}, {
				date: "2022-09-26",
				link: "【2022年党风廉政建设宣教月】“清风拂传媒 廉韵润初心” ​​​——传媒艺术学院开展廉政文化作品征集活动",
				to: "https://www.hubstc.com.cn/info/1070/10612.htm"
			}, {
				date: "2022-09-23",
				link: "【2022年党风廉政建设宣教月】基建处党支部召开集体廉政谈话及普法宣传培训会",
				to: "https://www.hubstc.com.cn/info/1070/10593.htm"
			}, {
				date: "2022-09-21",
				link: "【2022年党风廉政建设宣教月】传媒艺术学院开展廉政教育观影活动",
				to: "https://www.hubstc.com.cn/info/1070/10569.htm"
			}, {
				date: "2022-09-23",
				link: "【2022年党风廉政建设宣教月】财务处开展“纪法同行”专题暨“忠诚担当履职 昂扬奋进之姿”主题党日活动",
				to: "https://www.hubstc.com.cn/info/1070/10605.htm"
			}, {
				date: "2022-09-23",
				link: "【2022年党风廉政建设宣教月】以案为镜为鉴 严以修身律己 电信工程学院开展“书记讲廉政党课”活动”",
				to: "https://www.hubstc.com.cn/info/1070/10595.htm"
			}],
			currentPage: 1,
			pageSize: 10, // 每页显示5条记录 
		}
	}
};
</script>

<style scoped>
.clean-campus {
	padding: 20px;
}

.el-tables {
	font-size: 20px;
	font-weight: bolder;
}

.el-table-column {
	height: 600px;
}

a {
	color: inherit;
	/* 继承父元素的颜色 */
	text-decoration: none;
	/* 移除下划线 */
}

a:hover {
	color: inherit;
	/* 鼠标悬停时颜色不变 */
	text-decoration: none;
	/* 鼠标悬停时不显示下划线 */
}
.el-menu {
	background-color: #fff;
	padding-left: 300px;
	height: 80px;
	font-weight: bolder;
	padding-top: 30px;
}
.footer {
  background-color: rgb(206,223,226); 
  padding: 20px 0; /* 内边距 */
  text-align: center; /* 文字居中 */
  color: #333; /* 文字颜色 */
  font-size: 14px; /* 文字大小 */
}
 
.footer-content {
  display: flex; /* 使用 flex 布局 */
  flex-wrap: wrap; /* 允许换行 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中（在这个上下文中可能不太明显） */
  max-width: 1200px; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
}
 
.footer-section {
  flex: 1; /* 等宽布局 */
  min-width: 200px; /* 最小宽度 */
  padding: 10px; /* 内边距 */
  box-sizing: border-box; /* 包括内边距和边框在内计算宽度 */
}
 
.footer-section h3 {
  margin-top: 0; /* 去掉上边距 */
  font-size: 16px; /* 标题大小 */
  font-weight: bold; /* 加粗 */
}
 
.footer-section p {
  margin: 5px 0; /* 段落上下边距 */
}
 
.footer-section a {
  color: #007bff; /* 链接颜色 */
  text-decoration: none; /* 去掉下划线 */
}
 
.footer-section a:hover {
  text-decoration: underline; /* 悬停时显示下划线 */
}
 
.copyright {
  width: 100%; /* 全宽 */
  margin-top: 20px; /* 上边距 */
  border-top: 1px solid #ddd; /* 上边框 */
  padding-top: 10px; /* 上内边距 */
  font-size: 12px; /* 文字大小 */
}
</style>