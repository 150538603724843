<template>
	<div class="clean-campus">
		<div style="position: absolute;left: 0;top: 0;width: 100vw;height: 100%;display: flex;flex-direction: column;">
			<!-- 上面图片 -->
			<div><img style="width: 100%;margin: -108px;" src="/蓝绿粉色中国风廉洁文化微信公众号封面.jpg" @click="goToHome">
				<div style="flex: 1;background-image: url('/蓝绿粉色中国风廉洁文化微信公众号封面1.jpg');background-size: cover;">
					<MENU></MENU>
					<el-table :data="paginatedTableData" style="width: 100%;padding-left: 5%;" class="el-tables">
						<el-table-column class="el-table-column" width="1400">
							<template v-slot="scope">
								<a :href="generateLink(scope.row)" target="_blank">{{ scope.row.link }}</a>
							</template>
						</el-table-column>
						<el-table-column class="el-table-column" prop="date" width="150"></el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" :total="tableData.length" :page-size="pageSize"
						:current-page.sync="currentPage" @current-change="handlePageChange"></el-pagination>
				</div>
				<div class="footer">
					<div class="footer-content">
						<div class="footer-section">
							<h3>校区地址</h3>
							<p>关山校区：湖北省武汉市东湖高新区光谷一路206号</p>
							<p>鲁巷校区：湖北省武汉市洪山区珞瑜路1037号</p>
						</div>
						<div class="footer-section">
							<h3>联系方式</h3>
							<p>招生电话：027-87588821</p>
							<p>书记校长信箱 接访电话：027-87588771</p>
							<p>邮箱：hkzjyk@126.com</p>
						</div>
						<div class="footer-section">
							<h3>其他链接</h3>
							<p>
								<a href="https://www.hubstc.com.cn/" target="_blank" rel="noopener noreferrer">
									官网链接（点击跳转）
								</a>
							</p>
							<p>
								<a href="https://www.hbou.edu.cn/" target="_blank" rel="noopener noreferrer">
									开放大学
								</a>
							</p>
						</div>
						<div class="footer-section copyright">
							<p>&copy; 2024 湖北科技职业学院. 版权所有.</p>
							<p>鄂ICP备11015833号-2 </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MENU from './MENU.vue';
export default {
	name: 'CleanCampus',
	components: {
		MENU
	},
	computed: {
		paginatedTableData() {
			const start = (this.currentPage - 1) * this.pageSize;
			const end = this.currentPage * this.pageSize;
			return this.tableData.slice(start, end);
		},
	},
	methods: {
		handlePageChange(page) {
			this.currentPage = page;
		},
		generateLink(row) {
			return `${row.to}`;
		},
		goToHome() {
			this.$router.push('/')
		},
	},
	data() {
		return {
			tableData: [{
				date: "2024-05-10",
				link: "【清廉校园】基建处召开关山校区1号教学楼维修项目工地会议",
				to: "https://www.hubstc.com.cn/info/1070/13076.htm"
			}, {
				date: "2024-04-30",
				link: "【清廉校园】学校召开采购项目校内评委专题培训会",
				to: "https://www.hubstc.com.cn/info/1070/13056.htm"
			}, {
				date: "2023-12-05",
				link: "【清廉校园】找准“风险点” 构筑“防火墙” 学校召开招标代理机构廉洁提醒谈话会",
				to: "https://www.hubstc.com.cn/info/1070/12595.htm"
			}, {
				date: "2023-11-28",
				link: "【清廉校园】校团委开展“扬廉洁清风建文明校园”廉洁教育系列活动",
				to: "https://www.hubstc.com.cn/info/1070/12564.htm"
			}, {
				date: "2023-11-10",
				link: "【清廉校园】工商管理学院开展“学廉 说廉 守廉”辩论赛",
				to: "https://www.hubstc.com.cn/info/1070/12483.htm"
			}, {
				date: "2023-11-03",
				link: "【清廉校园】校纪委召开清廉校园建设相关工作评议会",
				to: "https://www.hubstc.com.cn/info/1070/12434.htm"
			}, {
				date: "2023-11-03",
				link: "【清廉校园】建设“廉洁基地” 开展“廉风行动” 培育“青廉力量” 电信工程学院学院大力推动廉洁文...",
				to: "https://www.hubstc.com.cn/info/1070/12402.htm"
			}, {
				date: "2023-10-23",
				link: "【清廉校园】学史明理、学史增信、学史悟廉 我校邀请刘秋阳教授作专题讲座",
				to: "https://www.hubstc.com.cn/info/1070/12371.htm"
			}, {
				date: "2023-10-17",
				link: "【清廉校园】严格加强监管 护航重点项目 采购与招标管理中心赴招标代理机构进行现场监标",
				to: "https://www.hubstc.com.cn/info/1070/12336.htm"
			}, {
				date: "2023-10-11",
				link: "【清廉校园】学校召开重点项目负责人集体廉政谈话会",
				to: "https://www.hubstc.com.cn/info/1070/12321.htm"
			}, {
				date: "2023-10-07",
				link: "【清廉校园】“扬清廉之风 做廉洁青年”机电工程学院师生赴廉政文化公园开展廉洁教育活动",
				to: "https://www.hubstc.com.cn/info/1070/12300.htm"
			}, {
				date: "2023-09-27",
				link: "【清廉校园】清风伴明月 廉洁过双节“两栋楼”建设项目开展节前廉政提醒活动",
				to: "https://www.hubstc.com.cn/info/1070/12279.htm"
			}, {
				date: "2023-05-16",
				link: "【清廉校园】学校召开2023年“清廉校园”建设推进会",
				to: "https://www.hubstc.com.cn/info/1068/11649.htm"
			}, {
				date: "2023-05-09",
				link: "【清廉校园】悟纪检监察初心 担纪律铁军使命 纪检监察干部队伍观看话剧《钱瑛》",
				to: "https://www.hubstc.com.cn/info/1070/11621.htm"
			}, {
				date: "2023-04-26",
				link: "【清廉校园】经贸学院丰富活动载体推动廉洁文化入脑入心",
				to: "https://www.hubstc.com.cn/info/1070/11541.htm"
			}, {
				date: "2023-04-24",
				link: "【清廉校园】书香传廉韵 学校举办诗歌朗诵比赛迎接“世界读书日",
				to: "https://www.hubstc.com.cn/info/1070/11524.htm"
			}, {
				date: "2023-04-18",
				link: "【清廉校园】“七个一”协同:持续推进廉洁教育走心、走新、走实",
				to: "https://www.hubstc.com.cn/info/1070/11486.htm"
			}, {
				date: "2022-10-19",
				link: "【清廉校园】采购与招标管理中心召开业务知识专题研学暨专项工作会",
				to: "https://www.hubstc.com.cn/info/1070/12564.htm"
			}, {
				date: "2022-10-17",
				link: "【清廉校园】清廉进校园 清风助成长 学校2022年党风廉政建设宣教月活动纪实",
				to: "https://www.hubstc.com.cn/info/1070/12564.htm"
			}, {
				date: "2022-10-14",
				link: "【清廉校园】增强廉洁意识锤炼务实作风 学校组织乡村振兴驻村工作队集体勤政廉政谈话",
				to: "https://www.hubstc.com.cn/info/1070/10712.htm"
			}, {
				date: "2022-10-14",
				link: "【清廉校园】清风与奋斗共进 青春与廉洁同行 校团委举办廉洁教育主题讲座暨2021-2022学年“青马班”结...",
				to: "https://www.hubstc.com.cn/info/1070/10711.htm"
			}, {
				date: "2023-07-25",
				link: "【清廉校园】弘扬清廉文化 建设清廉校园",
				to: "https://www.hubstc.com.cn/info/1070/10344.htm"
			}],
			currentPage: 1,
			pageSize: 10, // 每页显示5条记录 
		}
	}
};
</script>

<style scoped>
* {
	padding: 0;
	margin: 0;
}

.el-menu {
	background-color: #fff;
	padding-left: 300px;
	height: 80px;
	font-weight: bolder;
	padding-top: 30px;
}

.carousel-img {
	width: 100%;
	height: auto;
}

.clean-campus {
	padding: 20px;
}

.el-tables {
	font-size: 20px;
	font-weight: bolder;
}

.el-table-column {
	height: 600px;
}

a {
	color: inherit;
	/* 继承父元素的颜色 */
	text-decoration: none;
	/* 移除下划线 */
}

a:hover {
	color: inherit;
	/* 鼠标悬停时颜色不变 */
	text-decoration: none;
	/* 鼠标悬停时不显示下划线 */
}
.footer {
  background-color: rgb(206,223,226); 
  padding: 20px 0; /* 内边距 */
  text-align: center; /* 文字居中 */
  color: #333; /* 文字颜色 */
  font-size: 14px; /* 文字大小 */
}
 
.footer-content {
  display: flex; /* 使用 flex 布局 */
  flex-wrap: wrap; /* 允许换行 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中（在这个上下文中可能不太明显） */
  max-width: 1200px; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
}
 
.footer-section {
  flex: 1; /* 等宽布局 */
  min-width: 200px; /* 最小宽度 */
  padding: 10px; /* 内边距 */
  box-sizing: border-box; /* 包括内边距和边框在内计算宽度 */
}
 
.footer-section h3 {
  margin-top: 0; /* 去掉上边距 */
  font-size: 16px; /* 标题大小 */
  font-weight: bold; /* 加粗 */
}
 
.footer-section p {
  margin: 5px 0; /* 段落上下边距 */
}
 
.footer-section a {
  color: #007bff; /* 链接颜色 */
  text-decoration: none; /* 去掉下划线 */
}
 
.footer-section a:hover {
  text-decoration: underline; /* 悬停时显示下划线 */
}
 
.copyright {
  width: 100%; /* 全宽 */
  margin-top: 20px; /* 上边距 */
  border-top: 1px solid #ddd; /* 上边框 */
  padding-top: 10px; /* 上内边距 */
  font-size: 12px; /* 文字大小 */
}
</style>