// src/router/index.js  
import Vue from 'vue';  
import Router from 'vue-router';  
import Home from '@/components/HelloWorld.vue'  
import CleanCampus from '@/components/CleanCampus.vue';  
import PartyStyleMonth from '@/components/PartyStyleMonth.vue';  
import IntegrityEducationActivity from '@/components/IntegrityEducationActivity.vue';  

  
Vue.use(Router);  
  
export default new Router({  
  routes: [  
    {  
      path: '/',  
      name: 'Home',  
      component: Home  
    },  
    {  
      path: '/clean-campus',  
      name: 'CleanCampus',  
      component: CleanCampus  
    },  
    {  
      path: '/party-style-month',  
      name: 'PartyStyleMonth',  
      component: PartyStyleMonth  
    },  
    {  
      path: '/integrity-education-activity',  
      name: 'IntegrityEducationActivity',  
      component: IntegrityEducationActivity  
    }
  ]  
});